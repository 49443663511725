body,
html {
  height: auto;
}

.app {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.app-container {
  position: relative;
}

.app-inner {
  height: 100%;
  min-height: 100vh;
  flex: 1;
  padding: 2em 4em 4em;
}

.app-logo {
  position: absolute;
  right: 0;
  top: 0.5em;
  transform: scale(1) rotate(-20deg);
  opacity: 0;
}

.app-logo.is-animated {
  animation: scaleLogo 0.25s linear forwards;
  animation-delay: 0.5s;
}

@keyframes scaleLogo {
  0% {
    transform: scale(0) rotate(-20deg);
    opacity: 0;
  }
  50% {
    transform: scale(1.15) rotate(20deg);
  }
  100% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
}

.ui.header.app-header {
  color: #0270b9;
  text-transform: uppercase;
  line-height: 1.1;
}

.app-header-big {
  color: #005288;
  font-size: 1.3em;
}

.app-subHeader {
  color: #464547;
}

/* helpers */
.bi-m-section-top {
  /* margin-top: 2em; */
}

/* Media Queries */
/* Mobile */
@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }

  .app {
    padding: 0;
    padding-top: 1rem;
  }

  .app-inner {
    padding: 0 0 3rem 0;
  }

  .ui.header.app-header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .app-header-big {
    font-size: 1em;
  }
}

.app-footer {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .app-inner {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 45%,
      #ffffff 100%
    );
    clip-path: polygon(
      0% 0,
      0 0%,
      calc(100% - 5em) 0%,
      100% 5em,
      100% 100%,
      100% 100%,
      100% 100%,
      0% 100%
    );
  }

  .app-header__message {
    padding-right: 5em;
  }
}

.bi-accordion-content {
  position: relative;
}

.bi-accordion-content:after {
  content: " ";
  pointer-events: none;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(to top, white, rgba(255, 255, 255, 0));
}

.active.bi-accordion-content:after {
  display: none;
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen"]),
  [class*="tablet only"]:not([class*="widescreen"]),
  [class*="computer only"]:not([class*="widescreen"]),
  [class*="large screen only"]:not([class*="widescreen"]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}
